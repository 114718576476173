import { SuccessFilled } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import { getCombinedUserNames } from 'shared/utils'
import { GROUP_CODE } from 'shared/utils/constants'

import { globalI18n } from '@/locales'
import { CommonService } from '@/services/commons'
import { useCommonStore } from '@/stores/commons'
import { useMainStore } from '@/stores/main'
import { CommonCode, DrafterParseType } from '@/types'

export const parseDrafter = (payload: DrafterParseType) => {
  const { positionCode, employeeKoreanName, employeeEnglishName, englishName, koreanName } =
    payload ?? {}
  if (!positionCode) return ''
  const positionName = getPositionName(positionCode)
  const kName = employeeKoreanName ?? koreanName ?? ''
  const eName = employeeEnglishName ?? englishName ?? ''
  if (!kName && !englishName) return positionName
  return `${positionName} (${getCombinedUserNames(kName, eName)})`
}

export const getPositionName = (positionCode: string): string => {
  if (!positionCode) return ''

  const commonCodes = useCommonStore().commonCodes
  const position = commonCodes[GROUP_CODE.POSTN_CD]?.find(
    (item: CommonCode) => item.vmsCommonCode === positionCode
  )
  return position?.vmsCommonName ?? ''
}

export const showRequiredFieldToast = (message: string) => {
  setTimeout(() => {
    ElNotification({
      title: globalI18n.t('common.messages.error'),
      message: `${message} ${globalI18n.t('common.messages.requiredField')}`,
      position: 'top-left',
      type: 'error',
    })
  }, 0)
}

export const jasperReport = () => {
  useCommonStore().getFiles(1)
}

export const showSuccessNotification = (msg: string) => {
  ElNotification.success({
    icon: SuccessFilled,
    message: msg,
    customClass: 'complete-message',
    showClose: false,
  })
}

export const showErrorNotification = (message: string, title?: string) => {
  ElNotification.error({
    title: title ?? globalI18n.t('common.messages.error'),
    message,
    position: 'top-left',
  })
}
export const getPortInfoByCode = async (value: string) => {
  let portCode = ''
  let portName = ''
  if (!value.trim()) return { code: portCode, name: portName }
  await useMainStore().openLoadingOverlayWhen(async () => {
    await new CommonService()
      .getPortListV2({
        ncd: '',
        q: value,
        page: 0,
        size: 15,
      })
      .then((response) => {
        const portCodes = response.data ?? []
        const port = portCodes.find((port) => port.portCode?.toLowerCase() === value?.toLowerCase())
        if (port) {
          portCode = port?.portCode ?? ''
          portName = port?.portName ?? ''
        }
      })
  })
  return { code: portCode, name: portName }
}

export const getSupplierInfoByCode = async (value: string) => {
  let supplierCode = ''
  let supplierName = ''
  if (!value.trim()) return { code: supplierCode, name: supplierName }
  await useMainStore().openLoadingOverlayWhen(async () => {
    await new CommonService()
      .getSupplierListV2({
        ncd: '',
        q: value,
        page: 0,
        size: 15,
      })
      .then((response) => {
        const supplierList = response.data ?? []
        const supplier = supplierList.find(
          (supplier) => supplier.supplierCode?.toLowerCase() === value?.toLowerCase()
        )
        if (supplier) {
          supplierCode = supplier?.supplierCode ?? ''
          supplierName = supplier?.informationTradeName ?? ''
        }
      })
  })
  return { code: supplierCode, name: supplierName }
}

export const getVesselInfoByCode = async (value: string) => {
  let vesselCode = ''
  let vesselName = ''
  if (!value.trim()) return { code: vesselCode, name: vesselName }
  const vesselList = useCommonStore().vesselCodes ?? []
  const vessel = vesselList.find(
    (vessel) => vessel.vesselCode?.toLowerCase() === value?.toLowerCase()
  )
  if (vessel) {
    vesselCode = vessel?.vesselCode ?? ''
    vesselName = vessel?.vesselName ?? ''
  }
  return { code: vesselCode, name: vesselName }
}

export const notifySuccess = (successMessage: string) => {
  ElNotification({
    title: globalI18n.t('common.messages.success'),
    message: successMessage,
    customClass: 'success-message',
    type: 'success',
    position: 'top-right',
    duration: 3000,
  })
}
